<template>
  <div class="default-layout">
    <slot />
  </div>
</template>

<style scoped>
.default-layout {
  @apply flex min-h-full flex-col h-full;
}
</style>
